let getCSData = {};
export let contentstackLivePreviewWrapper = (fn) => {};

if(typeof window !== "undefined") {
  const livePreviewModule = await import("gatsby-source-contentstack/live-preview");
  const livePreviewUtilsModule = await import("@contentstack/live-preview-utils");

  const { ContentstackGatsby } = livePreviewModule;
  const ContentstackLivePreview  = livePreviewUtilsModule.default;


  getCSData = new ContentstackGatsby({
      api_key: process.env.GATSBY_CS_PREVIEW_API_KEY,
      environment: process.env.GATSBY_CS_PREVIEW_ENV,
      delivery_token: process.env.GATSBY_CS_PREVIEW_DELIVERY_TOKEN,
      live_preview: {
          management_token: process.env.GATSBY_CS_PREVIEW_MANAGEMENT_TOKEN,
          enable: true,
          host: "api.contentstack.io" // "eu-api.contentstack.com" for EU region
      }
  });

  ContentstackLivePreview.init({
      stackSdk: getCSData.stackSdk,
  });

  contentstackLivePreviewWrapper = (fetchLivePreviewData) => {
    if(typeof window === "undefined") {
      return;
    }
    ContentstackLivePreview.onLiveEdit(fetchLivePreviewData);
  }
}

export const fetchLivePreviewData = (data, setFn, pageContext, dataTransformationFn) => {
    return async () => {
      let updatedData = await getCSData.get(data);

      if(pageContext) {
        updatedData.pageContext = pageContext;
      }

      if(dataTransformationFn) {
        updatedData = dataTransformationFn(updatedData);
      }

      setFn(updatedData)
    }
}